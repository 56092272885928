import React from "react";

/**
 *
 * @param {*} Component
 * @param {*} firebasePathProvider as (props) => {key: {path, isCollection}}
 */
export default function withFocus(Component) {
  return class FocusComponent extends React.Component {
    componentDidMount() {
      // Force refresh at least every 10 minutes
      this.refreshInterval = window.setInterval(this.handleFocus, 10 * 60 * 1000);
      window.addEventListener("focus", this.handleFocus);
    }

    componentWillUnmount() {
      window.clearInterval(this.refreshInterval);
      window.removeEventListener("focus", this.handleFocus);
    }

    handleFocus = () => {
      this.forceUpdate();
    };

    render() {
      return <Component {...this.props} />;
    }
  };
}
