/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import "./App.scss";
import { Navbar, Tabs, Tab, Container } from "react-bootstrap";
import withFirebase from "./hoc/withFirebase";
import { db } from "./core/firebase";
import User from "./components/User";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.users[0].id
    };
  }

  handleUserChange = user => this.setState({ user });

  render() {
    const { users } = this.props;
    const { user } = this.state;
    return (
      <div>
        <Navbar bg="primary" variant="dark">
          <Navbar.Brand>🏆 Bon points</Navbar.Brand>
        </Navbar>
        <Container
          style={{
            marginTop: 8,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden"
          }}
        >
          <Tabs
            id="controlled-tab-example"
            className="nav-fill nav-pills"
            activeKey={user}
            onSelect={this.handleUserChange}
            variant="tabs"
          >
            {users.map(user => (
              <Tab
                key={user.id}
                tabClassName={user.color ? `nav--with-label nav-${user.color}` : "nav--with-label"}
                title={
                  <>
                    <div className="tab__name">{user.name}</div>
                    <div className="tab__label">{user.points}</div>
                  </>
                }
                eventKey={user.id}
              >
                <User {...this.props} user={user} />
              </Tab>
            ))}
          </Tabs>
        </Container>
      </div>
    );
  }
}

export default withFirebase(App, () => ({
  users: db.collection("users"),
  shop: db.collection("shop"),
  tasks: db.collection("tasks"),
  transactions: db.collection("transactions")
}));
