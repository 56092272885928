import React, { Component } from "react";
import orderBy from "lodash/orderBy";
import { Table, Button } from "react-bootstrap";
import moment from "moment";
import { fromNow } from "../utils";

export function taskIsDoneToday(taskId, transactions) {
  const today = moment().format("YYYY-MM-DD");
  const lastOccurence = transactions.find(t => t.rewardId === taskId);
  // const canBuy = points >= item.cost;
  return lastOccurence && lastOccurence.date === today;
}

class Tasks extends Component {
  render() {
    const { tasks, transactions, onWin, onCancel } = this.props;
    const today = moment().format("YYYY-MM-DD");
    const currentTasks = orderBy(tasks, "order");
    return (
      <Table>
        <tbody>
          {currentTasks.map(item => {
            const lastOccurence = transactions.find(t => t.rewardId === item.id);
            // const canBuy = points >= item.cost;
            const hasDoneToday = lastOccurence && lastOccurence.date === today;
            return (
              <tr key={item.id} className={!hasDoneToday ? "item-available" : "item-unavailable"}>
                <td style={{ verticalAlign: "middle", paddingTop: 0, paddingBottom: 0 }}>
                  <div className="item-name">{item.name}</div>
                  {lastOccurence && (
                    <div className="item-details">
                      <small>{fromNow(lastOccurence.datetime)}</small>
                    </div>
                  )}
                </td>
                {/* <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.points}</td> */}
                <td className="td--button">
                  <Button
                    variant={hasDoneToday ? "success" : "outline-success"}
                    onClick={() => (hasDoneToday ? onCancel(lastOccurence) : onWin(item))}
                  >
                    ✓
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default Tasks;
