import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyDHJbB1HqNd9VrxKaRc7jaZAf-Wm2GYkrU",
  authDomain: "home-2e87d.firebaseapp.com",
  databaseURL: "https://home-2e87d.firebaseio.com",
  projectId: "home-2e87d",
  storageBucket: "home-2e87d.appspot.com",
  messagingSenderId: "102279762230",
  appId: "1:102279762230:web:0f86d462867dc2c2712397"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase
  .firestore()
  .collection("bops")
  .doc("praz");
export default firebase;
