import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { db } from "../core/firebase";
import moment from "moment";
import "moment/locale/fr";
import sumBy from "lodash/sumBy";
import withFirebase from "../hoc/withFirebase";
import withFocus from "../hoc/withFocus";
import Tasks, { taskIsDoneToday } from "./Tasks";
import Shop from "./Shop";
moment.locale("fr");

function availableItems(items, userId) {
  return items.filter(r => {
    // console.log("available", r.availableFor);
    if (!r.availableFor) return true;
    return r.availableFor.indexOf(userId) !== -1;
  });
}

class User extends Component {
  dbPath = () => db.collection("users").doc(this.props.user.id);

  handleWin = reward => {
    const { user } = this.props;
    console.log("win", reward);

    const now = moment();

    const date = now.format("YYYY-MM-DD");
    const id = date + "_" + reward.id;
    this.dbPath()
      .collection("transactions")
      .doc(id)
      .set({ ...reward, id, type: "reward", rewardId: reward.id, date, datetime: now.toDate() });
    this.dbPath().update({ points: user.points + reward.points });
  };

  handleCancelWin = transaction => {
    const { user } = this.props;
    if (window.confirm("Etes-vous sûr de vouloir annuler ce point ?")) {
      this.dbPath()
        .collection("transactions")
        .doc(transaction.id)
        .delete();
      this.dbPath().update({ points: user.points - transaction.points });
    }
  };

  handleBuy = shopItem => {
    const { user } = this.props;
    console.log("buy", shopItem);

    const date = moment().format("YYYY-MM-DD");
    const dateId = moment().format("YYYY-MM-DD_HH-mm");
    const id = dateId + "_" + shopItem.id;
    this.dbPath()
      .collection("transactions")
      .doc(id)
      .set({ ...shopItem, id, type: "shopItem", shopId: shopItem.id, date, datetime: new Date() });
    this.dbPath().update({ points: user.points - shopItem.cost });
  };

  handleCancelBuy = transaction => {
    const { user } = this.props;
    if (window.confirm("Etes-vous sûr de vouloir annuler cet achat ?")) {
      this.dbPath()
        .collection("transactions")
        .doc(transaction.id)
        .delete();
      this.dbPath().update({ points: user.points + transaction.cost });
    }
  };

  render() {
    const { user, shop, tasks, transactions } = this.props;
    console.log(
      user.name,
      sumBy(transactions, t => t.points || -t.cost)
    );
    const userTasks = availableItems(tasks, user.id);
    const userShop = availableItems(shop, user.id);
    console.log(user.id, userShop);
    const finishedTasks = userTasks.filter(task => taskIsDoneToday(task.id, transactions));

    // console.log("lastTransactions", lastTransactions);
    return (
      <div className="content">
        {/* Hello {user.name} */}
        <Card border="success">
          <Card.Header>
            Gagner des points
            <div style={{ float: "right" }}>
              {sumBy(finishedTasks, "points")} / {sumBy(userTasks, "points")}
            </div>
          </Card.Header>
          <Tasks
            user={user}
            tasks={userTasks}
            onWin={this.handleWin}
            onCancel={this.handleCancelWin}
            transactions={transactions}
          />
        </Card>
        <Card border="danger">
          <Card.Header>Utiliser les points</Card.Header>
          <Shop
            user={user}
            shop={userShop}
            onBuy={this.handleBuy}
            onCancel={this.handleCancelBuy}
            transactions={transactions}
          />
        </Card>
      </div>
    );
  }
}

export default withFocus(
  withFirebase(User, ({ user }) => ({
    transactions: db
      .collection("users")
      .doc(user.id)
      .collection("transactions")
      .orderBy("datetime", "desc")
  }))
);
