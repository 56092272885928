import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { fromNow } from "../utils";

class Shop extends Component {
  render() {
    const { user, shop, transactions, onBuy, onCancel } = this.props;
    const { points } = user;

    const today = moment().format("YYYY-MM-DD");
    return (
      <Table>
        <tbody>
          {orderBy(shop, "cost", "asc").map(item => {
            const canBuy = points >= item.cost;
            const lastOccurence = transactions.find(t => t.shopId === item.id);
            // const canBuy = points >= item.cost;
            const hasDoneToday = lastOccurence && lastOccurence.date === today;

            return (
              <tr key={item.id} className={canBuy ? "item-available" : "item-unavailable"}>
                <td style={{ verticalAlign: "middle", paddingTop: 0, paddingBottom: 0 }}>
                  <div className="item-name">{item.name}</div>
                  {lastOccurence && (
                    <div className="item-details">
                      <small>{fromNow(lastOccurence.datetime)}</small>
                    </div>
                  )}
                </td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.cost}</td>
                <td className="td--button">
                  {hasDoneToday ? (
                    <Button variant={"success"} onClick={() => onCancel(lastOccurence)}>
                      ✓
                    </Button>
                  ) : (
                    <Button
                      disabled={!canBuy}
                      variant={canBuy ? "outline-danger" : "outline-secondary"}
                      onClick={() => onBuy(item)}
                    >
                      +
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default Shop;
